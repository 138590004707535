import { render } from "./TableBankStatements.vue?vue&type=template&id=1c5e1b40"
import script from "./TableBankStatements.vue?vue&type=script&lang=ts"
export * from "./TableBankStatements.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QTr from 'quasar/src/components/table/QTr.js';
import QTd from 'quasar/src/components/table/QTd.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QTr,QTd});
