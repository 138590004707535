
import { defineComponent } from "vue";
import SideNavigation from "@/modules/sideNavigation/SideNavigation.vue";

export default defineComponent({
    components: {
        SideNavigation: SideNavigation,
    },
    data() {
        return {
            isMenuOpen: true,
        };
    },
    computed: {
        sidebarStateClass(): string {
            return this.isMenuOpen ? "sidebar-open" : "";
        },
    },
    methods: {
        toggleMenu(value: boolean) {
            this.isMenuOpen = value;
        },
    },
});
