import { render } from "./PageBankStatements.vue?vue&type=template&id=95c62ca8&scoped=true"
import script from "./PageBankStatements.vue?vue&type=script&lang=ts"
export * from "./PageBankStatements.vue?vue&type=script&lang=ts"

import "./PageBankStatements.vue?vue&type=style&index=0&id=95c62ca8&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-95c62ca8"

export default script
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn});
