const generator = (start: number, end: number) =>
    Array.from({ length: end + 1 - start }, (_v, k) => k + start);

export function checkAndSetYearAndQuarter(
    year: number,
    quarter: number,
    minYear: number,
    maxYear: number,
    minQuarter: number,
    maxQuarter: number
): [year: number, quarter: number] {
    const years = buildYearOptions(minYear, maxYear);
    const quarters = buildQuarterOptions(
        year,
        minYear,
        maxYear,
        minQuarter,
        maxQuarter
    );
    if (years.includes(year) && quarters.includes(quarter)) {
        return [year, quarter];
    }
    return [minYear, minQuarter];
}

export function buildYearOptions(minYear: number, maxYear: number): number[] {
    if (minYear > maxYear) {
        return [];
    }
    return generator(minYear, maxYear).reverse();
}

export function buildQuarterOptions(
    year: number,
    minYear: number,
    maxYear: number,
    minQuarter: number,
    maxQuarter: number
): number[] {
    if (minYear > maxYear || (minYear == maxYear && minQuarter > maxQuarter)) {
        return [];
    }
    if (minYear == maxYear && minQuarter <= maxQuarter) {
        return generator(minQuarter, maxQuarter);
    }
    if (year == minYear) {
        return generator(minQuarter, 4);
    }
    if (year == maxYear) {
        return generator(1, maxQuarter);
    }
    return generator(1, 4);
}
