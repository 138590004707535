
import { PAGES } from "@/config/routing";
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        open: {
            default: false,
            type: Boolean,
        },
    },
    emits: ["open"],
    computed: {
        pages(): Record<keyof typeof PAGES, PAGES> {
            return PAGES;
        },
    },
    methods: {
        toggleMenu(value: boolean) {
            this.$emit("open", value);
        },
    },
});
