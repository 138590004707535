
import { defineComponent } from "vue";
import Table from "@/shared/elements/Table.vue";
import FiltersBankStatements from "./FiltersBankStatements.vue";
import { Column } from "@/models/Table";
import { BankStatement, Filters } from "@/models/BankStatement";
import { PaginatedData, Pagination } from "@/models/Pagination";
import BankStatementsApi from "../data/BankStatementsApi";

export default defineComponent({
    name: "TableBankStatements",
    components: {
        Table,
        FiltersBankStatements,
    },
    data() {
        return {
            bankStatements: [] as BankStatement[],
            pagination: {
                page: 1,
                rowsPerPage: 50,
                rowsNumber: 0,
            } as Pagination,
            columns: [
                {
                    name: "filename",
                    label: this.$t("bankStatements.list.filename"),
                    field: "filename",
                    align: "left",
                },
                {
                    name: "importDate",
                    label: this.$t("bankStatements.list.importDate"),
                    field: "importDate",
                    align: "left",
                },
                {
                    name: "transferDate",
                    label: this.$t("bankStatements.list.transferDate"),
                    field: "transferDate",
                    align: "left",
                },
                {
                    name: "paymentDetails",
                    label: this.$t("bankStatements.list.paymentDetails"),
                    field: "name",
                    align: "left",
                },
                {
                    name: "amount",
                    label: this.$t("bankStatements.list.amount"),
                    field: "amount",
                    align: "right",
                },
            ] as Column<BankStatement>[],
            filters: {
                text: "",
                date: "",
            } as Filters,
        };
    },
    mounted() {
        this.updateBankStatements();
    },
    methods: {
        updatePagination(pagination: Pagination) {
            this.pagination = pagination;
            this.updateBankStatements();
        },
        updateFilters(filters: Filters) {
            this.filters = filters;
            this.pagination.page = 1;
            this.updateBankStatements();
        },
        updateBankStatements() {
            BankStatementsApi.getBankStatements(
                this.pagination.page,
                this.pagination.rowsPerPage > 0
                    ? this.pagination.rowsPerPage
                    : this.pagination.rowsNumber,
                this.filters
            ).then((result: PaginatedData<BankStatement>) => {
                this.bankStatements = result.data;
                this.pagination = {
                    ...this.pagination,
                    rowsNumber: result.totalElements,
                };
            });
        },
    },
});
