import { PaginatedData } from "@/models/Pagination";
import { FilterParams, Filters, Invoice } from "@/models/Invoice";
import axios from "axios";
import { removeEmptyParams } from "@/utils/RequestUtils";

const InvoiceApi = {
    path: "invoices",
    getInvoices: async (
        page: number,
        size: number,
        filters: Filters
    ): Promise<PaginatedData<Invoice>> => {
        return axios
            .get<PaginatedData<Invoice>>(InvoiceApi.path, {
                params: {
                    page: page - 1,
                    size,
                    ...getFilterParameters(filters),
                },
            })
            .then(({ data }) => data);
    },
};

const getFilterParameters = (filters: Filters): FilterParams => {
    const params = { ...filters } as FilterParams & {
        date?: { from: string; to: string };
    };
    if (filters.date && params.date) {
        params.fromDate = filters.date.from;
        params.toDate = filters.date.to;
        delete params.date;
    }

    return removeEmptyParams(params);
};

export default InvoiceApi;
