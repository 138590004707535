import { bankStatement } from "@/mock/BankStatements";
import { BankStatement, FilterParams, Filters } from "@/models/BankStatement";
import { PaginatedData } from "@/models/Pagination";
import { removeEmptyParams } from "@/utils/RequestUtils";
import axios from "axios";

const BankStatementsApi = {
    path: "accountStatements",
    importBankStatements: async (
        file: File,
        bankAccountId: string,
        duplicatesEnabled: boolean
    ): Promise<{ result: string; numberOfDuplicates: number }> => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("bankAccountId", bankAccountId);
        formData.append("duplicatesEnabled", duplicatesEnabled.toString());

        return axios
            .post<{ data: { result: string; numberOfDuplicates: number } }>(
                `${BankStatementsApi.path}/upload`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            )
            .then(({ data }) => data.data);
    },
    getBankStatements: async (
        page: number,
        size: number,
        filters: Filters
    ): Promise<PaginatedData<BankStatement>> => {
        return Promise.resolve({
            data: [
                { ...bankStatement, id: page + 1 + "", amount: 10.99 + page },
                {
                    ...bankStatement,
                    id: page + 2 + "",
                    amount: 10.99 + page + 1,
                },
                {
                    ...bankStatement,
                    id: page + 1 + "",
                    amount: 10.99 + page + 2,
                },
            ],
            totalElements: 6,
            totalPages: 2,
        });

        // return axios
        //     .get<PaginatedData<BankStatement>>(BankStatementsApi.path, {
        //         params: {
        //             page: page - 1,
        //             size,
        //             ...getFilterParameters(filters);
        //         },
        //     })
        //     .then(({ data }) => data);
    },
};

const getFilterParameters = (filters: Filters): FilterParams => {
    const params = { ...filters } as FilterParams & {
        date?: { from: string; to: string } | "";
    };

    if (filters.date) {
        params.fromDate = filters.date.from;
        params.toDate = filters.date.to;
        delete params.date;
    }

    return removeEmptyParams(params);
};

export default BankStatementsApi;
