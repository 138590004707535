<template>
    <q-input
        :model-value="inputValue"
        class="month-input"
        outlined
        bg-color="white"
        debounce="600"
        mask="##.####"
        :label="label || $t('general.filter.timePeriod')"
        :error="!dateIsValid"
        @update:model-value="updateFromInput"
        ><template #error>
            {{ $t("general.inputError") }}
        </template>
        <template #append>
            <q-icon name="event" class="cursor-pointer">
                <q-popup-proxy
                    ref="monthPicker"
                    transition-show="scale"
                    transition-hide="scale"
                >
                    <q-date
                        minimal
                        emit-immediately
                        :model-value="modelValue"
                        mask="YYYY/MM"
                        default-view="Years"
                        :navigation-min-year-month="minYearMonthLimit"
                        :navigation-max-year-month="maxYearMonthLimit"
                        @update:model-value="updateFromQDate"
                    >
                        <div class="row items-center justify-end">
                            <q-btn
                                v-close-popup
                                :label="$t('general.close')"
                                color="primary"
                                flat
                            />
                        </div>
                    </q-date>
                </q-popup-proxy>
            </q-icon>
        </template>
    </q-input>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MonthInputService from "@/shared/elements/MonthInput/MonthInputService";
import { QPopupProxy } from "quasar";

export default defineComponent({
    name: "MonthInput",
    props: {
        modelValue: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            default: "",
        },
        minYearMonthLimit: {
            type: String,
            default: "2016/01",
        },
    },
    emits: ["update:modelValue"],
    data() {
        return {
            dateIsValid: true,
            maxYearMonthLimit: `${new Date().getFullYear()}/${
                new Date().getMonth() + 1
            }`,
        };
    },
    computed: {
        inputValue(): string {
            if (!this.modelValue) {
                return "";
            } else {
                return MonthInputService.transformDateForUser(
                    this.modelValue,
                    this.minYearMonthLimit,
                    this.maxYearMonthLimit
                );
            }
        },
    },
    methods: {
        updateFromInput(newValue: string) {
            try {
                this.dateIsValid = true;
                this.$emit(
                    "update:modelValue",
                    MonthInputService.normalizeDate(
                        newValue,
                        this.minYearMonthLimit,
                        this.maxYearMonthLimit
                    )
                );
            } catch (e) {
                this.dateIsValid = false;
            }
        },
        updateFromQDate(newValue: string, reason: string) {
            if (typeof newValue === "string" && reason === "month") {
                try {
                    this.dateIsValid = true;

                    this.$emit("update:modelValue", newValue);
                    (this.$refs.monthPicker as QPopupProxy).hide();
                } catch (e) {
                    this.dateIsValid = false;
                }
            }
            return "";
        },
    },
});
</script>

<style lang="scss" scoped>
.month-input {
    max-width: 230px;
}
</style>
