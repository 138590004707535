export enum COGNITOGROUP {
    ANY = "*",
    ADMINISTRATOR = "Administrator",
    SUPERVISOR = "Supervisor",
    FIBU = "Fibu",
    KONTO_OPS = "Konto-Ops",
    ZAHLUNGSEINGANGS_ZUORDNUNG = "Zahlungseingangs-Zuordnung",
    FIRST_LEVEL_SUPPORT = "1stLevelSupport",
    SECOND_LEVEL_SUPPORT = "2ndLevelSupport",
}

export default {
    mandatorySignIn: true,
    region: "eu-central-1",
    userPoolId: process.env.VUE_APP_COGNITO_USERPOOL_ID,
    userPoolWebClientId: process.env.VUE_APP_COGNITO_ID,
    oauth: {
        domain: process.env.VUE_APP_COGNITO_DOMAIN,
        scope: ["email"],
        redirectSignIn: process.env.VUE_APP_REDIRECT_SIGN_IN_URL,
        redirectSignOut: process.env.VUE_APP_REDIRECT_SIGN_OUT_URL,
        clientId: process.env.VUE_APP_COGNITO_ID,
        responseType: process.env.VUE_APP_COGNITO_RESPONSE_TYPE,
    },
};
