
import { defineComponent, PropType } from "vue";
import { Filters } from "@/models/Invoice";
import ContentFilters from "@/shared/elements/ContentFilters.vue";
import DateInput from "@/shared/elements/DateInput/DateInput.vue";

export default defineComponent({
    name: "InvoiceFilters",
    components: { ContentFilters, DateInput },
    props: {
        modelValue: {
            type: Object as PropType<Filters>,
            required: true,
        },
        availableCountries: {
            type: Array as PropType<string[]>,
            default: () => [],
        },
    },
    emits: ["update:modelValue"],
    methods: {
        updateFilters(key: string, value: unknown) {
            this.$emit("update:modelValue", {
                ...this.modelValue,
                [key]: value,
            });
        },
    },
});
