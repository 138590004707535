import axios, { AxiosRequestConfig, ResponseType } from "axios";

const ReportsApi = {
    createMonthlyReport: async (year: number, month: number): Promise<void> => {
        const responseType: ResponseType = "blob";
        const config = {
            params: {
                year,
                month,
            },
            responseType: responseType,
        };
        const filename = `report-${month}_${year}.zip`;

        return getReport("reports", config, filename, /filename=(\w+\.zip)/);
    },
    createQuarterlyReportCHLI: async (
        year: number,
        quarter: number
    ): Promise<void> => {
        const responseType: ResponseType = "blob";
        const config = {
            params: {
                year,
                quarter,
            },
            responseType: responseType,
        };

        const filename = `picdrop Umsätze ${year}Q${quarter}.xlsx`;

        return getReport(
            `quarterly-reports/CHLI`,
            config,
            filename,
            /filename=(\w+\.xlsx)/
        );
    },
    createQuarterlyReportGB: async (
        year: number,
        quarter: number
    ): Promise<void> => {
        const responseType: ResponseType = "blob";
        const config = {
            params: {
                year,
                quarter,
            },
            responseType: responseType,
        };

        const filename = `picdrop Umsätze ${year}Q${quarter}.xlsx`;

        return getReport(
            `quarterly-reports/GB`,
            config,
            filename,
            /filename=(\w+\.xlsx)/
        );
    },
    createCostCenterReport: async (
        year: number,
        month: number
    ): Promise<void> => {
        const responseType: ResponseType = "blob";
        const config = {
            params: {
                year,
                month,
            },
            responseType: responseType,
        };

        const filename = `Umsatzkostenstellen ${year}-${String(month).padStart(
            2,
            "0"
        )}.xlsx`;

        return getReport(
            "cost-center-reports",
            config,
            filename,
            /filename=(\w+\.xlsx)/
        );
    },
};

function getReport(
    url: string,
    config: AxiosRequestConfig,
    filename: string,
    regex: RegExp
): Promise<void> {
    return axios.get(url, config).then((response) => {
        const fileUrl = window.URL.createObjectURL(
            new Blob([response.data], {
                type: "application/octet-stream",
            })
        );
        // download file
        const link = document.createElement("a");
        link.href = fileUrl;

        // set filename for file
        const matcher = regex.exec(response.headers["content-disposition"]);

        if (matcher && matcher[1]) {
            filename = matcher[1];
        }

        link.setAttribute("download", filename);
        link.click();
        link.remove();
    });
}

export default ReportsApi;
