
import { defineComponent } from "vue";

export default defineComponent({
    name: "Dialog",
    props: {
        confirmButtonLabel: {
            type: String,
            default: "",
        },
        cancelButtonLabel: {
            type: String,
            default: "",
        },
        closeButtonLabel: {
            type: String,
            default: "",
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },

        dialogType: {
            type: String,
            default: "confirm",
        },
        isClosable: {
            type: Boolean,
            default: true,
        },
    },
    emits: ["confirm", "cancel"],

    methods: {
        onDialogChange(isVisible: boolean) {
            if (!isVisible) {
                this.$emit("cancel");
            }
        },
        onConfirm() {
            this.$emit("confirm");
        },
    },
});
