import axios from "axios";

const BankAccountsApi = {
    path: "bankAccounts",
    getBankAccounts: async (): Promise<{ id: string; name: string }[]> => {
        return axios
            .get<{ data: { id: string; name: string }[] }>(BankAccountsApi.path)
            .then(({ data }) => data.data);
    },
};
export default BankAccountsApi;
