import { render } from "./FiltersBankStatements.vue?vue&type=template&id=3d57120a&scoped=true"
import script from "./FiltersBankStatements.vue?vue&type=script&lang=ts"
export * from "./FiltersBankStatements.vue?vue&type=script&lang=ts"

import "./FiltersBankStatements.vue?vue&type=style&index=0&id=3d57120a&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-3d57120a"

export default script
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QInput,QIcon});
