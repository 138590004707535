
import { defineComponent } from "vue";
import DialogUploadBankStatements from "@/pages/bankStatements/components/DialogUploadBankStatements.vue";
import TableBankStatements from "./components/TableBankStatements.vue";

export default defineComponent({
    name: "PageBankStatements",
    components: {
        DialogUploadBankStatements,
        TableBankStatements,
    },
    data() {
        return {
            isUploadDialogOpen: false,
        };
    },
});
