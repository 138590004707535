<template>
    <ContentFilters>
        <template #filters>
            <q-input
                :model-value="modelValue.text"
                outlined
                bg-color="white"
                :label="$t('bankStatements.filter.search')"
                :debounce="600"
                @update:model-value="updateFilters('text', $event)"
                ><template #prepend><q-icon name="search" /></template
            ></q-input>
            <DateInput
                class="filter-input-date"
                :model-value="modelValue.date"
                :label="$t('bankStatements.filter.transferDate')"
                @update:model-value="updateFilters('date', $event)"
            />
        </template>
    </ContentFilters>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import ContentFilters from "@/shared/elements/ContentFilters.vue";
import DateInput from "@/shared/elements/DateInput/DateInput.vue";
import { Filters } from "@/models/BankStatement";

export default defineComponent({
    name: "FiltersBankStatements",
    components: { ContentFilters, DateInput },
    props: {
        modelValue: {
            type: Object as PropType<Filters>,
            required: true,
        },
    },
    emits: ["update:modelValue"],
    methods: {
        updateFilters(key: string, value: unknown) {
            this.$emit("update:modelValue", {
                ...this.modelValue,
                [key]: value,
            });
        },
    },
});
</script>

<style lang="scss" scoped>
.filter-input-date {
    flex-grow: 0;
    width: 320px;
}
</style>
