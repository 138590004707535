<template>
    <q-input
        :model-value="inputValue"
        class="date-input"
        outlined
        bg-color="white"
        debounce="600"
        :label="label || $t('general.filter.timePeriod')"
        @update:model-value="updateFromInput"
    >
        <template #append>
            <q-icon name="event" class="cursor-pointer">
                <q-popup-proxy transition-show="scale" transition-hide="scale">
                    <q-date
                        :model-value="qDateValue"
                        mask="YYYY-MM-DD"
                        range
                        :title="inputValue"
                        @update:model-value="updateFromQDate"
                    >
                        <div class="row items-center justify-end">
                            <q-btn
                                v-close-popup
                                :label="$t('general.close')"
                                color="primary"
                                flat
                            />
                        </div>
                    </q-date>
                </q-popup-proxy>
            </q-icon>
        </template>
    </q-input>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import DateInputService from "./DateInputService";

export default defineComponent({
    name: "DateInput",
    props: {
        modelValue: {
            type: [String, Object] as PropType<{ from: string; to: string }>,
            required: true,
        },
        label: {
            type: String,
            default: "",
        },
    },
    emits: ["update:modelValue"],
    computed: {
        qDateValue(): string | { from: string; to: string } {
            return this.modelValue &&
                this.modelValue.from === this.modelValue.to
                ? this.modelValue.from
                : this.modelValue;
        },
        inputValue(): string {
            if (!this.modelValue) {
                return "";
            }
            if (this.modelValue.from === this.modelValue.to) {
                return this.$d(new Date(this.modelValue.from), "short");
            } else {
                return `${this.$d(
                    new Date(this.modelValue.from),
                    "short"
                )} - ${this.$d(new Date(this.modelValue.to), "short")}`;
            }
        },
    },
    methods: {
        updateFromInput(newValue: string) {
            try {
                const date = DateInputService.getDate(
                    newValue,
                    this.$i18n.locale
                );
                this.$emit("update:modelValue", date);
            } catch (e) {
                console.error(e);
            }
        },
        updateFromQDate(newValue: string | { from: string; to: string }) {
            if (typeof newValue === "string") {
                this.$emit("update:modelValue", {
                    from: newValue,
                    to: newValue,
                });
            } else {
                this.$emit("update:modelValue", newValue);
            }
        },
    },
});
</script>

<style lang="scss" scoped>
.date-input {
    min-width: 230px;
}
</style>
