<template>
    <div>
        <ContentFilters>
            <template #filters>
                <q-input
                    :model-value="modelValue.text"
                    outlined
                    bg-color="white"
                    :label="$t('invoices.filter.search')"
                    :debounce="600"
                    @update:model-value="updateFilters('text', $event)"
                    ><template #prepend><q-icon name="search" /></template
                ></q-input>
                <div class="filter-input-date">
                    <DateInput
                        class="date-input"
                        :model-value="modelValue.date"
                        @update:model-value="updateFilters('date', $event)"
                    />
                </div>
                <q-select
                    :model-value="modelValue.country"
                    class="filter-select-country"
                    outlined
                    bg-color="white"
                    :multiple="true"
                    :options="availableCountries"
                    :label="$t('invoices.filter.country')"
                    @update:model-value="updateFilters('country', $event)"
                />
                <q-input
                    type="number"
                    :model-value="modelValue.amount"
                    class="filter-input-amount"
                    outlined
                    bg-color="white"
                    :debounce="600"
                    :label="$t('invoices.filter.amount')"
                    @update:model-value="updateFilters('amount', $event)"
                />
            </template>
            <template #filters-bottom>
                <q-checkbox
                    v-for="status in ['OUTSTANDING', 'PAID', 'CANCELED']"
                    :key="status"
                    :model-value="modelValue.status"
                    :val="status"
                    :label="$t('receipt.status.' + status.toLowerCase())"
                    @update:model-value="updateFilters('status', $event)"
                />
            </template>
        </ContentFilters>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Filters } from "@/models/Invoice";
import ContentFilters from "@/shared/elements/ContentFilters.vue";
import DateInput from "@/shared/elements/DateInput/DateInput.vue";

export default defineComponent({
    name: "InvoiceFilters",
    components: { ContentFilters, DateInput },
    props: {
        modelValue: {
            type: Object as PropType<Filters>,
            required: true,
        },
        availableCountries: {
            type: Array as PropType<string[]>,
            default: () => [],
        },
    },
    emits: ["update:modelValue"],
    methods: {
        updateFilters(key: string, value: unknown) {
            this.$emit("update:modelValue", {
                ...this.modelValue,
                [key]: value,
            });
        },
    },
});
</script>
<style lang="scss" scoped>
.filter-input-date {
    flex-grow: 0;
}

.filter-input-amount {
    flex-grow: 0;
    width: 100px;
}

.filter-select-country {
    flex-grow: 0;
    width: 25%;
}
</style>
