import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import PageDashboard from "@/pages/dashboard/PageDashboard.vue";
import PageInvoices from "@/pages/invoices/PageInvoices.vue";
import PageBankStatements from "@/pages/bankStatements/PageBankStatements.vue";
import PageError from "@/pages/error/PageError.vue";
import PageBasicContent from "@/pages/basicContent/PageBasicContent.vue";
import PageReports from "@/pages/reports/PageReports.vue";
import { PAGES } from "@/config/routing";
import checkPermissions from "@/modules/authentication/authenticationGuard";

const routes: Array<RouteRecordRaw> = [
    {
        path: PAGES.DASHBOARD,
        component: PageBasicContent,
        children: [
            { path: "", component: PageDashboard },
            { path: PAGES.INVOICES, component: PageInvoices },
            { path: PAGES.BANK_STATEMENTS, component: PageBankStatements },
            { path: PAGES.REPORTS, component: PageReports },
        ],
    },

    { path: PAGES.ERROR, component: PageError },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach(checkPermissions);

export default router;
