<template>
    <h1 v-t="'bankStatements.title'"></h1>
    <div class="open-upload-container">
        <q-btn
            color="primary"
            :label="$t('bankStatements.bankStatementsUpload')"
            @click="isUploadDialogOpen = true"
        />
    </div>
    <DialogUploadBankStatements
        :is-dialog-open="isUploadDialogOpen"
        @close-upload-dialog="isUploadDialogOpen = false"
    ></DialogUploadBankStatements>
    <TableBankStatements />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DialogUploadBankStatements from "@/pages/bankStatements/components/DialogUploadBankStatements.vue";
import TableBankStatements from "./components/TableBankStatements.vue";

export default defineComponent({
    name: "PageBankStatements",
    components: {
        DialogUploadBankStatements,
        TableBankStatements,
    },
    data() {
        return {
            isUploadDialogOpen: false,
        };
    },
});
</script>
<style lang="scss" scoped>
@import "src/styles/variables";
.open-upload-container {
    text-align: right;
    margin-bottom: $spacing-3;
}
</style>
