
import { defineComponent } from "vue";
import MonthInputService from "@/shared/elements/MonthInput/MonthInputService";
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';;

export default defineComponent({
    name: "MonthInput",
    props: {
        modelValue: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            default: "",
        },
        minYearMonthLimit: {
            type: String,
            default: "2016/01",
        },
    },
    emits: ["update:modelValue"],
    data() {
        return {
            dateIsValid: true,
            maxYearMonthLimit: `${new Date().getFullYear()}/${
                new Date().getMonth() + 1
            }`,
        };
    },
    computed: {
        inputValue(): string {
            if (!this.modelValue) {
                return "";
            } else {
                return MonthInputService.transformDateForUser(
                    this.modelValue,
                    this.minYearMonthLimit,
                    this.maxYearMonthLimit
                );
            }
        },
    },
    methods: {
        updateFromInput(newValue: string) {
            try {
                this.dateIsValid = true;
                this.$emit(
                    "update:modelValue",
                    MonthInputService.normalizeDate(
                        newValue,
                        this.minYearMonthLimit,
                        this.maxYearMonthLimit
                    )
                );
            } catch (e) {
                this.dateIsValid = false;
            }
        },
        updateFromQDate(newValue: string, reason: string) {
            if (typeof newValue === "string" && reason === "month") {
                try {
                    this.dateIsValid = true;

                    this.$emit("update:modelValue", newValue);
                    (this.$refs.monthPicker as QPopupProxy).hide();
                } catch (e) {
                    this.dateIsValid = false;
                }
            }
            return "";
        },
    },
});
