<template>
    <h1 v-t="'invoices.title'"></h1>
    <TableInvoices />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import TableInvoices from "@/modules/invoices/TableInvoices.vue";

export default defineComponent({
    name: "PageInvoices",
    components: {
        TableInvoices,
    },
});
</script>
