<template>
    <h1>Dashboard</h1>
    <h2>Headline 2</h2>
    <h2>Headline 3</h2>
    <p>
        Normaler Text: Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
        sed diam nonumy eirmod tempor invidunt ut labore et dolore magna
        aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo
        dolores et ea rebum.
        <strong>Stet clita kasd gubergren</strong>, no sea takimata sanctus est
        Lorem ipsum dolor sit amet.<br />
        <small>
            Kleiner Text: Lorem ipsum dolor sit amet, consetetur sadipscing
            elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore
            magna aliquyam erat, sed diam voluptua.</small
        >
    </p>
    <p>
        <q-icon
            v-for="size in ['xs', 'sm', 'md', 'lg', 'xl']"
            :key="size"
            :size="size"
            name="logout"
        />
    </p>
    <p>
        <a href="#">Beispiel Link</a>
        &nbsp; &nbsp;
        <q-btn color="primary" label="Primary" />
    </p>
    <p>
        Inline-Übersetzung: {{ $t("test") }}<br />
        Übersetzung mit Komponente: <i18n-t keypath="test" />
    </p>
</template>
