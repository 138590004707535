import { render } from "./SideNavigation.vue?vue&type=template&id=3657d5e0&scoped=true"
import script from "./SideNavigation.vue?vue&type=script&lang=ts"
export * from "./SideNavigation.vue?vue&type=script&lang=ts"

import "./SideNavigation.vue?vue&type=style&index=0&id=3657d5e0&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-3657d5e0"

export default script
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QMenu,QList,QItem,QIcon,QSeparator,QItemLabel});
