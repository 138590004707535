
import { defineComponent } from "vue";
import { Invoice, Filters } from "@/models/Invoice";
import { PaginatedData, Pagination } from "@/models/Pagination";
import { Column } from "@/models/Table";
import Table from "@/shared/elements/Table.vue";
import InvoiceFilters from "./components/InvoiceFilters.vue";
import InvoiceApi from "./data/InvoiceApi";
import CountryApi from "./data/CountryApi";

export default defineComponent({
    name: "TableInvoices",
    components: { InvoiceFilters, Table },
    data() {
        return {
            invoices: [] as Invoice[],
            pagination: {
                page: 1,
                rowsPerPage: 50,
                rowsNumber: 0,
            } as Pagination,
            columns: [
                {
                    name: "invoiceId",
                    label: this.$t("invoices.list.invoiceId"),
                    field: "invoiceId",
                    align: "left",
                    required: true,
                },
                {
                    name: "creationDate",
                    label: this.$t("invoices.list.creationDate"),
                    field: "creationDate",
                    format: (date: string) => this.$d(new Date(date), "short"),
                    align: "left",
                    required: true,
                },
                {
                    name: "recipient",
                    label: this.$t("invoices.list.recipient"),
                    field: "recipient",
                    format: (recipient: string, row: Invoice) =>
                        `${recipient} (${row.accountId})`,
                    align: "left",
                    required: true,
                },
                {
                    name: "country",
                    label: this.$t("invoices.list.recipient"),
                    field: "country",
                    align: "left",
                    required: true,
                },
                {
                    name: "status",
                    label: this.$t("invoices.list.status"),
                    field: "status",
                    format: (status: string) =>
                        this.$t(`receipt.status.${status.toLowerCase()}`),
                    align: "left",
                    required: true,
                },
                {
                    name: "amount",
                    label: this.$t("invoices.list.amount"),
                    field: "grossAmount",
                    format: (grossAmount: number, row: Invoice) =>
                        `${grossAmount.toFixed(2)} ${row.currency}`,
                    align: "right",
                    required: true,
                },
            ] as Column<Invoice>[],
            filters: {
                text: "",
                date: "",
                country: [],
                amount: 0,
                status: [],
            } as Filters,
            availableCountries: [] as string[],
        };
    },
    mounted() {
        this.updateInvoices();
        this.updateAvailableCountries();
    },
    methods: {
        updatePagination(pagination: Pagination) {
            this.pagination = pagination;
            this.updateInvoices();
        },
        updateFilters(filters: Filters) {
            this.filters = filters;
            this.pagination.page = 1;
            this.updateInvoices();
        },
        updateInvoices() {
            InvoiceApi.getInvoices(
                this.pagination.page,
                this.pagination.rowsPerPage > 0
                    ? this.pagination.rowsPerPage
                    : this.pagination.rowsNumber,
                this.filters
            ).then((result: PaginatedData<Invoice>) => {
                this.invoices = result.data;
                this.pagination = {
                    ...this.pagination,
                    rowsNumber: result.totalElements,
                };
            });
        },
        updateAvailableCountries() {
            CountryApi.getCountries().then((countries: string[]) => {
                this.availableCountries = countries;
            });
        },
    },
});
