import axios from "axios";
import qs from "qs";

export const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
export const setApiDefaults = (): void => {
    axios.defaults.baseURL = apiBaseUrl;
    axios.defaults.paramsSerializer = (params: unknown) => {
        return qs.stringify(params, { indices: false });
    };
};
