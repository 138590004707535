<template>
    <SideNavigation
        :open="isMenuOpen"
        @open="toggleMenu($event)"
    ></SideNavigation>

    <div class="content-wrapper" :class="sidebarStateClass">
        <router-view></router-view>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SideNavigation from "@/modules/sideNavigation/SideNavigation.vue";

export default defineComponent({
    components: {
        SideNavigation: SideNavigation,
    },
    data() {
        return {
            isMenuOpen: true,
        };
    },
    computed: {
        sidebarStateClass(): string {
            return this.isMenuOpen ? "sidebar-open" : "";
        },
    },
    methods: {
        toggleMenu(value: boolean) {
            this.isMenuOpen = value;
        },
    },
});
</script>

<style lang="scss" scoped>
@import "src/styles/variables";

.content-wrapper {
    padding: 0 $spacing-4;
    transition: padding $transition-fast;

    &.sidebar-open {
        padding-left: $sidebar-navigation-width + $spacing-4;
    }
}
</style>
