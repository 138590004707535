import { render } from "./QuarterSelect.vue?vue&type=template&id=52defd3a&scoped=true"
import script from "./QuarterSelect.vue?vue&type=script&lang=ts"
export * from "./QuarterSelect.vue?vue&type=script&lang=ts"

import "./QuarterSelect.vue?vue&type=style&index=0&id=52defd3a&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-52defd3a"

export default script
import QSelect from 'quasar/src/components/select/QSelect.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QSelect});
