
import { defineComponent, PropType } from "vue";
import { Column } from "@/models/Table";
import { Pagination } from "@/models/Pagination";

const defaultPagination = {
    sortBy: "desc",
    descending: false,
    page: 1,
    rowsPerPage: 50,
    rowsNumber: 0,
};

export default defineComponent({
    name: "Table",
    props: {
        data: {
            type: Object as PropType<unknown[]>,
            required: true,
        },
        columns: {
            type: Object as PropType<Column<unknown>[]>,
            required: true,
        },
        uniqueProp: {
            type: String,
            default: "id",
        },
        customBody: {
            type: Boolean,
            default: false,
        },
        pagination: {
            type: Object as PropType<Pagination>,
            default: () => defaultPagination,
        },
    },
    emits: ["update:pagination"],
    data() {
        return {
            // local pagination is a copy of the prop pagination,
            // it is used as a value for v-model
            localPagination: defaultPagination,
        };
    },
    watch: {
        pagination: {
            handler: function (oldValue, newValue) {
                if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
                    this.localPagination = {
                        ...defaultPagination,
                        ...this.pagination,
                    };
                }
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        onRequest({ pagination }: { pagination: Pagination } & unknown) {
            this.$emit("update:pagination", pagination);
        },
    },
});
