import { createI18n } from "vue-i18n";
import messages_de from "./de.json";

const options = {
    locale: "de",
    fallbackLocale: "de",
    messages: {
        de: messages_de,
    },
    datetimeFormats: {
        de: {
            short: {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
            } as const,
        },
    },
};

export default createI18n(options);
