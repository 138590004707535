import axios from "axios";
import { getAccessToken } from "./authenticationService";

export default function (): void {
    axios.interceptors.request.use(
        async (config) => {
            const token = await getAccessToken();
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        },
        function (err) {
            return Promise.reject(err);
        }
    );
}
