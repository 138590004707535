<template>
    <Dialog
        v-if="isOpen"
        :is-disabled="!file || !bankAccount"
        :dialog-type="isImportSuccess ? 'alert' : 'confirm'"
        :is-closable="!isSpinnerVisible"
        @confirm="upload"
        @cancel="closeUploadDialog"
    >
        <template #title>{{
            $t("bankStatements.bankStatementsUpload")
        }}</template>
        <template v-if="!isImportSuccess" #description>{{
            $t("bankStatements.uploadDescription")
        }}</template>
        <template #content>
            <template v-if="!isImportSuccess">
                <q-file
                    v-model="file"
                    accept=".xls"
                    outlined
                    max-files="1"
                    class="user-input-field"
                    :label="$t('bankStatements.file')"
                >
                    <template #append>
                        <q-icon name="attach_file" @click.stop />
                    </template>
                </q-file>
                <q-select
                    v-model="bankAccount"
                    class="user-input-field"
                    outlined
                    bg-color="white"
                    :multiple="false"
                    :options="availableBankAccounts"
                    :label="$t('bankStatements.bankAccount')"
                />

                <q-linear-progress
                    v-if="isSpinnerVisible"
                    size="10px"
                    indeterminate
                />
            </template>

            <template v-else>
                <div class="upload-success">
                    <q-icon name="done" />
                    {{ $t("bankStatements.upload.success") }}
                </div>
            </template>
        </template>
    </Dialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Dialog from "@/shared/elements/Dialog.vue";
import BankStatementsApi from "@/pages/bankStatements/data/BankStatementsApi";
import BankAccountsApi from "@/pages/bankStatements/data/BankAccountsApi";

export default defineComponent({
    name: "DialogUploadBankStatements",
    components: {
        Dialog,
    },
    props: {
        isDialogOpen: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["close-upload-dialog"],
    data() {
        return {
            file: null as File | null,
            bankAccount: null as { label: string; value: string } | null,
            availableBankAccounts: [] as
                | { label: string; value: string }[]
                | null,
            isSpinnerVisible: false,
            isImportSuccess: false,
            duplicatesEnabled: false,
            isOpen: this.isDialogOpen,
        };
    },
    watch: {
        isDialogOpen(isOpened) {
            if (isOpened) {
                this.resetUploadDialog();
                this.isOpen = this.isDialogOpen;
            }
        },
    },
    mounted() {
        this.getBankAccounts();
    },
    methods: {
        upload() {
            this.isSpinnerVisible = true;
            if (this.file && this.bankAccount) {
                BankStatementsApi.importBankStatements(
                    this.file,
                    this.bankAccount.value,
                    this.duplicatesEnabled
                ).then(() => {
                    this.isSpinnerVisible = false;
                    this.isImportSuccess = true;
                });
            }
        },
        getBankAccounts() {
            BankAccountsApi.getBankAccounts().then(
                (accounts: { id: string; name: string }[]) => {
                    this.availableBankAccounts = accounts.map((account) => ({
                        label: account.name,
                        value: account.id,
                    }));
                }
            );
        },
        resetUploadDialog() {
            if (this.isImportSuccess) {
                this.isOpen = false;
                this.isImportSuccess = false;
                this.bankAccount = null;
                this.file = null;
            }
        },
        closeUploadDialog() {
            this.isOpen = false;
            this.$emit("close-upload-dialog");
        },
    },
});
</script>

<style lang="scss" scoped>
@import "src/styles/variables";

.user-input-field:not(:last-child) {
    padding-bottom: $spacing-2;
}
.q-file {
    cursor: pointer;
}
.upload-success {
    .q-icon {
        width: 100%;
        font-size: 5em;
        padding-bottom: $spacing-3;
    }
}
</style>
