export const bankStatement = {
    id: "0",
    filename: "test-filename.xls",
    importDate: "2021-05-01",
    transferDate: "2021-04-28",
    name: "John Doe",
    iban: "DE02100500000054540402",
    description: "Test Überweisung",
    amount: 11.99,
    currency: "EUR",
};

export const filters = {
    text: "foo",
    date: { from: "2021-07-01", to: "2021-08-01" },
};
