
import { defineComponent } from "vue";
import { signOut } from "@/modules/authentication/authenticationService";

export default defineComponent({
    methods: {
        logOut() {
            signOut();
        },
    },
});
