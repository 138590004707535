
import { defineComponent } from "vue";
import Dialog from "@/shared/elements/Dialog.vue";
import BankStatementsApi from "@/pages/bankStatements/data/BankStatementsApi";
import BankAccountsApi from "@/pages/bankStatements/data/BankAccountsApi";

export default defineComponent({
    name: "DialogUploadBankStatements",
    components: {
        Dialog,
    },
    props: {
        isDialogOpen: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["close-upload-dialog"],
    data() {
        return {
            file: null as File | null,
            bankAccount: null as { label: string; value: string } | null,
            availableBankAccounts: [] as
                | { label: string; value: string }[]
                | null,
            isSpinnerVisible: false,
            isImportSuccess: false,
            duplicatesEnabled: false,
            isOpen: this.isDialogOpen,
        };
    },
    watch: {
        isDialogOpen(isOpened) {
            if (isOpened) {
                this.resetUploadDialog();
                this.isOpen = this.isDialogOpen;
            }
        },
    },
    mounted() {
        this.getBankAccounts();
    },
    methods: {
        upload() {
            this.isSpinnerVisible = true;
            if (this.file && this.bankAccount) {
                BankStatementsApi.importBankStatements(
                    this.file,
                    this.bankAccount.value,
                    this.duplicatesEnabled
                ).then(() => {
                    this.isSpinnerVisible = false;
                    this.isImportSuccess = true;
                });
            }
        },
        getBankAccounts() {
            BankAccountsApi.getBankAccounts().then(
                (accounts: { id: string; name: string }[]) => {
                    this.availableBankAccounts = accounts.map((account) => ({
                        label: account.name,
                        value: account.id,
                    }));
                }
            );
        },
        resetUploadDialog() {
            if (this.isImportSuccess) {
                this.isOpen = false;
                this.isImportSuccess = false;
                this.bankAccount = null;
                this.file = null;
            }
        },
        closeUploadDialog() {
            this.isOpen = false;
            this.$emit("close-upload-dialog");
        },
    },
});
