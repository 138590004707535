import Auth from "@aws-amplify/auth";
import Amplify from "@aws-amplify/core";
import authentication, { COGNITOGROUP } from "@/config/authentication";
import { CognitoUser, CognitoUserSession } from "amazon-cognito-identity-js";
import { PAGES } from "@/config/routing";

const originalUrlKey = "original_url";
const errorNotPermitted = "user is not permitted";
const authenticationUrl =
    `https://${authentication.oauth.domain}/login` +
    `?client_id=${authentication.userPoolWebClientId}` +
    `&response_type=${authentication.oauth.responseType}` +
    `&scope=openid` +
    `&redirect_uri=${authentication.oauth.redirectSignIn}`;

export const initAmplifyAuthentication = (): void => {
    Amplify.configure({
        Auth: {
            ...authentication,
        },
    });
};

export const authenticateUser = async (): Promise<CognitoUser> => {
    return Auth.currentAuthenticatedUser()
        .then((user: CognitoUser) => {
            const cognitoGroups = getCognitoGroups(user);

            if (cognitoGroups.length > 0) {
                // user has got permissions
                redirectAfterLogin();
            } else if (window.location.pathname !== PAGES.ERROR) {
                // user has not got permissions
                redirectToError();
            }
            return user;
        })
        .catch((error: string) => {
            if (error !== errorNotPermitted) {
                // user is not authenticated
                redirectToLogin();
            }

            // pass error to other observers
            throw error;
        });
};

export const deleteTokens = (): void => {
    const localStorageKeys = Object.keys(window.localStorage);
    localStorageKeys.forEach((key) => {
        if (key.indexOf("CognitoIdentityServiceProvider.") === 0) {
            window.localStorage.removeItem(key);
        }
    });
};

export const signOut = async (): Promise<void> => {
    return await Auth.signOut();
};

export const getAccessToken = async (): Promise<string> => {
    return Auth.currentSession()
        .then((session: CognitoUserSession) => {
            return session.getAccessToken().getJwtToken();
        })
        .catch((error: string) => {
            redirectToLogin();
            throw error;
        });
};

export const isUserAllowed = async (
    allowedGroups: string[]
): Promise<boolean> => {
    const user = await authenticateUser();
    const cognitoGroups = getCognitoGroups(user);
    return allowedGroups.some((allowedGroup) =>
        cognitoGroups.includes(allowedGroup)
    );
};

const getCognitoGroups = (user: CognitoUser): string[] => {
    const accessToken = user.getSignInUserSession()?.getAccessToken();
    const allGroups =
        (accessToken?.payload["cognito:groups"] as string[]) || [];
    // ignore all unknown groups
    return allGroups.filter((group) =>
        (Object.values(COGNITOGROUP) as string[]).includes(group)
    );
};

const redirectAfterLogin = () => {
    // get cached origin url from initial visiting
    const localStorageOriginUrl = window.localStorage.getItem(originalUrlKey);
    if (localStorageOriginUrl) {
        // remove cached url from local storage
        window.localStorage.removeItem(originalUrlKey);
        // redirect user to initial url
        window.location.assign(window.location.origin + localStorageOriginUrl);
    }
};

const redirectToLogin = () => {
    // cache origin url for initial visiting
    window.localStorage.setItem(originalUrlKey, window.location.pathname);
    // redirect to cognito login
    window.location.assign(authenticationUrl);
};

const redirectToError = () => {
    window.location.assign(window.location.origin + PAGES.ERROR);
    throw errorNotPermitted;
};
