<template>
    <q-btn
        v-if="!open"
        class="open-menu"
        icon="menu"
        rounded
        color="primary"
        :label="$t('menu.label')"
        @click="toggleMenu(true)"
    ></q-btn>
    <div>
        <q-menu :model-value="open" persistent>
            <q-list>
                <q-item
                    clickable
                    class="close-menu"
                    size="sm"
                    @click="toggleMenu(false)"
                >
                    <small>
                        {{ $t("general.close") }}&nbsp;<q-icon
                            size="xs"
                            name="close"
                        />
                    </small>
                </q-item>
                <q-separator />
                <q-item clickable :to="pages.DASHBOARD" :exact="true">
                    <q-icon name="home" />{{ $t("menu.dashboard") }}
                </q-item>
                <q-separator />
                <q-item-label header>{{
                    $t("menu.manualPayments")
                }}</q-item-label>
                <q-item :to="pages.BANK_STATEMENTS">{{
                    $t("menu.bankStatements")
                }}</q-item>
                <q-item> Zahlungen zuordnen </q-item>
                <q-item> Zuordnungsbericht </q-item>
                <q-separator />
                <q-item :to="pages.INVOICES">
                    {{ $t("menu.invoices") }}
                </q-item>
                <q-separator />
                <q-item :to="pages.REPORTS">
                    {{ $t("menu.reports") }}
                </q-item>
            </q-list>
        </q-menu>
    </div>
</template>

<script lang="ts">
import { PAGES } from "@/config/routing";
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        open: {
            default: false,
            type: Boolean,
        },
    },
    emits: ["open"],
    computed: {
        pages(): Record<keyof typeof PAGES, PAGES> {
            return PAGES;
        },
    },
    methods: {
        toggleMenu(value: boolean) {
            this.$emit("open", value);
        },
    },
});
</script>

<style lang="scss" scoped>
@import "src/styles/variables";
.q-list {
    width: 220px;
}

.q-item {
    padding-top: 12px;
    padding-left: $spacing-3;

    .q-icon {
        display: inline-block;
        line-height: 1.2rem;
        font-size: 1.715em;

        margin-right: $spacing-1;
    }

    &.q-router-link--active {
        pointer-events: none;
        background: $color-primary-lighter;
    }
}

a {
    color: $color-text-main;

    &:hover {
        color: $color-primary;
    }

    &.q-btn--active {
        color: $color-primary-darker;
    }
}
.open-menu {
    float: left;
    margin-top: $spacing-2;
    margin-right: $spacing-4;
    margin-left: $spacing-1;
}
.close-menu {
    justify-content: flex-end;
    min-height: 1.5rem;

    padding-top: 2px;
    padding-bottom: 0;

    .q-icon {
        line-height: 0.8;
        vertical-align: middle;
        margin-right: 0;
    }
}
</style>
