import axios, { AxiosError, AxiosResponse } from "axios";
import Dialog from 'quasar/src/plugins/Dialog.js';;
import i18n from "@/i18n";
import router from "@/router";
import { PAGES } from "@/config/routing";
import { deleteTokens } from "../authentication/authenticationService";

function showErrorModal(error: AxiosError) {
    const { t } = i18n.global;
    const errorDetails = error.response
        ? `<small>
        <strong>${t("error.unknown.details")}</strong><br/>
            ${error.response.status} - ${error.response.data.error} | ${
              error.response.data.message
          }
        </small>`
        : "";
    Dialog.create({
        title: "",
        message: `<p>${t("error.unknown.message")}</p>` + errorDetails,
        html: true,
    });
}

export default function (): void {
    axios.interceptors.response.use(
        function onFullFilled(response: AxiosResponse<unknown>) {
            return response;
        },
        function onRejected(error: AxiosError) {
            switch (error.response?.status) {
                case 401:
                    deleteTokens();
                    router.push(PAGES.ERROR);
                    break;
                case 403:
                    router.push(PAGES.ERROR);
                    break;
                default:
                    showErrorModal(error);
            }

            return Promise.reject(error);
        }
    );
}
