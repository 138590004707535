import { localizedDateToIso } from "@/i18n/utils";

const DateInputService = {
    isValidDate: (dateString: string): boolean => {
        const date = new Date(dateString);
        return date instanceof Date && !isNaN(date.getTime());
    },
    getDate: (
        newValue: string,
        currentLocale: string
    ): string | { from: string; to: string } => {
        const dateRanges = newValue.split(" - ");
        if (!newValue) {
            return "";
        }

        if (dateRanges.length === 1) {
            const date = localizedDateToIso(newValue, currentLocale);
            if (DateInputService.isValidDate(date)) {
                return { from: date, to: date };
            }
        } else {
            const startDate = localizedDateToIso(dateRanges[0], currentLocale);
            const endDate = localizedDateToIso(dateRanges[1], currentLocale);
            if (
                DateInputService.isValidDate(startDate) &&
                DateInputService.isValidDate(endDate)
            ) {
                return { from: startDate, to: endDate };
            }
        }

        throw "not a valid date";
    },
};

export default DateInputService;
