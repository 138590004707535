<template>
    <div class="content-wrapper">
        <h1 v-t="'error.authentication.title'"></h1>
        <span v-t="'error.authentication.message'"></span>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    name: "PageError",
    data() {
        return {};
    },
});
</script>

<style lang="scss" scoped>
@import "src/styles/variables";
.content-wrapper {
    margin: $spacing-3;
}
</style>
