<template>
    <header>
        <div class="brand">
            <img src="picdrop-logo.svg" />
            <span class="name">{{ $t("general.appName") }}</span>
            <span>{{ $t("general.appSubline") }}</span>
        </div>
        <div class="actions">
            <q-btn
                flat
                round
                padding="none"
                color="white"
                icon="logout"
                @click="logOut()"
            />
        </div>
    </header>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { signOut } from "@/modules/authentication/authenticationService";

export default defineComponent({
    methods: {
        logOut() {
            signOut();
        },
    },
});
</script>

<style lang="scss" scoped>
@import "src/styles/variables";
@import "src/styles/typography/typography-mixins";

header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 64px;

    color: $color-basic-white;
    background: $color-dark;

    border-bottom: $spacing-1 solid $color-primary;
    padding: $spacing-1;
}

.brand {
    display: flex;
    align-items: center;
    height: 100%;

    img {
        height: 100%;

        background: $color-basic-white;

        border: 2px solid $color-basic-white;
        margin-right: $spacing-1;
    }

    .name {
        @include h1;

        letter-spacing: -0.05em;
        margin-right: $spacing-1;
    }
}
</style>
