import { COGNITOGROUP } from "@/config/authentication";
import { PAGES, routesPermissions } from "@/config/routing";
import { isUserAllowed } from "@/modules/authentication/authenticationService";
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

export default async function (
    to: RouteLocationNormalized,
    _from: RouteLocationNormalized,
    next: NavigationGuardNext
): Promise<void> {
    const allowedGroups = routesPermissions[to.path as PAGES];
    if (
        allowedGroups.includes(COGNITOGROUP.ANY) ||
        (await isUserAllowed(allowedGroups))
    ) {
        return next();
    }

    return next({ path: PAGES.ERROR });
}
