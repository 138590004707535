export const localizedDateToIso = (
    dateString: string,
    currentLocale: string
): string => {
    if (currentLocale === "de") {
        const dateParts = dateString.match(/^(\d{2})\.(\d{2})\.(\d{4})$/);
        if (dateParts) {
            return `${dateParts[3]}-${dateParts[2]}-${dateParts[1]}`;
        }
        throw "not a valid date";
    }

    return dateString;
};
