<template>
    <q-select
        :model-value="selectedYear"
        :options="yearOptions"
        :label="yearLabel"
        class="quarter-select"
        outlined
        bg-color="white"
        @update:model-value="updateYear"
    />
    <q-select
        :model-value="selectedQuarter"
        :options="quarterOptions"
        :label="quarterLabel"
        class="quarter-select"
        outlined
        bg-color="white"
        @update:model-value="updateQuarter"
    />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
    buildQuarterOptions,
    buildYearOptions,
    checkAndSetYearAndQuarter,
} from "@/shared/elements/QuarterSelect/QuarterSelectService";

export default defineComponent({
    name: "QuarterSelect",
    props: {
        year: {
            type: Number,
            required: true,
        },
        quarter: {
            type: Number,
            required: true,
        },
        minYear: {
            type: Number,
            required: true,
        },
        minQuarter: {
            type: Number,
            required: true,
        },
        maxYear: {
            type: Number,
            required: true,
        },
        maxQuarter: {
            type: Number,
            required: true,
        },
        yearLabel: {
            type: String,
            required: true,
        },
        quarterLabel: {
            type: String,
            required: true,
        },
    },
    emits: ["update:year", "update:quarter"],
    data() {
        return {
            selectedYear: 2022,
            selectedQuarter: 1,
            quarterOptions: [1, 2, 3, 4],
            yearOptions: [2022],
        };
    },
    created() {
        const [year, quarter] = checkAndSetYearAndQuarter(
            this.year,
            this.quarter,
            this.minYear,
            this.maxYear,
            this.minQuarter,
            this.maxQuarter
        );
        this.updateYear(year);
        this.updateQuarter(quarter);
        this.yearOptions = buildYearOptions(this.minYear, this.maxYear);
        this.quarterOptions = buildQuarterOptions(
            this.selectedYear,
            this.minYear,
            this.maxYear,
            this.minQuarter,
            this.maxQuarter
        );
    },
    methods: {
        updateYear(newYear: number) {
            this.selectedYear = newYear;
            this.quarterOptions = buildQuarterOptions(
                this.selectedYear,
                this.minYear,
                this.maxYear,
                this.minQuarter,
                this.maxQuarter
            );
            this.$emit("update:year", newYear);
            this.updateQuarter(this.quarterOptions[0]);
        },
        updateQuarter(newQuarter: number) {
            this.selectedQuarter = newQuarter;
            this.$emit("update:quarter", newQuarter);
        },
    },
});
</script>

<style lang="scss" scoped>
.quarter-select {
    min-width: 100px;
    margin-right: 5px;
}
</style>
