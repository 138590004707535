<template>
    <q-dialog
        :model-value="true"
        :persistent="!isClosable"
        @update:model-value="onDialogChange()"
    >
        <q-card>
            <q-card-section class="q-pt-none">
                <h3><slot name="title"></slot></h3>
                <p><slot name="description"></slot></p>
                <slot name="content" />
            </q-card-section>

            <q-card-actions align="right">
                <template v-if="dialogType === 'confirm'">
                    <q-btn
                        v-close-popup
                        flat
                        class="secondary"
                        :label="cancelButtonLabel || $t('general.cancel')"
                        :disable="!isClosable"
                    />
                    <q-btn
                        flat
                        :label="confirmButtonLabel || $t('general.confirm')"
                        :disable="isDisabled || !isClosable"
                        class="primary"
                        @click="onConfirm()"
                    />
                </template>
                <template v-if="dialogType === 'alert'">
                    <q-btn
                        v-close-popup
                        flat
                        :label="closeButtonLabel || $t('general.close')"
                        :disable="!isClosable"
                        class="primary"
                    />
                </template>
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    name: "Dialog",
    props: {
        confirmButtonLabel: {
            type: String,
            default: "",
        },
        cancelButtonLabel: {
            type: String,
            default: "",
        },
        closeButtonLabel: {
            type: String,
            default: "",
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },

        dialogType: {
            type: String,
            default: "confirm",
        },
        isClosable: {
            type: Boolean,
            default: true,
        },
    },
    emits: ["confirm", "cancel"],

    methods: {
        onDialogChange(isVisible: boolean) {
            if (!isVisible) {
                this.$emit("cancel");
            }
        },
        onConfirm() {
            this.$emit("confirm");
        },
    },
});
</script>

<style lang="scss">
@import "src/styles/variables";

.q-card {
    width: 460px;
    max-width: 70vw;

    padding: $spacing-3;

    p:not(:empty) {
        padding: $spacing-2 0;
    }
}

.q-card-actions {
    padding-top: $spacing-2;
}
</style>
