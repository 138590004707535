
import { defineComponent } from "vue";
import MonthInput from "@/shared/elements/MonthInput/MonthInput.vue";
import ReportsApi from "@/pages/reports/data/ReportsApi";
import QuarterSelect from "@/shared/elements/QuarterSelect/QuarterSelect.vue";
import ImportsApi from "@/pages/reports/data/ImportsApi";
import { ImportStatus } from "@/models/ImportStatus";

const MIN_YEAR_MONTH_LIMIT = "2016/01";
const lastMonthDate = new Date();
lastMonthDate.setDate(0);
const LAST_MONTH = `${lastMonthDate.getFullYear()}/${
    lastMonthDate.getMonth() + 1
}`;

const date = new Date();
const RECENT_QUARTER = Math.ceil((date.getMonth() + 1) / 3);

const LAST_YEAR =
    RECENT_QUARTER == 1 ? date.getFullYear() - 1 : date.getFullYear();
const LAST_QUARTER = RECENT_QUARTER == 1 ? 4 : RECENT_QUARTER - 1;
const MIN_YEAR = 2016;
const MIN_QUARTER = 1;

export default defineComponent({
    name: "PageReports",
    components: { QuarterSelect, MonthInput },
    data() {
        return {
            monthlyReportDate: LAST_MONTH,
            minYearMonthLimit: MIN_YEAR_MONTH_LIMIT,

            yearCHLI: LAST_YEAR,
            quarterCHLI: LAST_QUARTER,
            yearGB: LAST_YEAR,
            quarterGB: LAST_QUARTER,
            minYear: MIN_YEAR,
            minQuarter: MIN_QUARTER,
            maxYear: date.getFullYear(),
            maxQuarter: RECENT_QUARTER,

            costCenterReportDate: LAST_MONTH,

            isSpinnerVisible: false,
            isError: false,
            isImportStatusError: false,
            isImportRunning: false,
            remainingFilesToImport: 0,
            intervalId: 0,
        };
    },
    mounted() {
        this.getImportStatus();
        this.intervalId = window.setInterval(
            () => this.getImportStatus(),
            10000
        );
    },
    unmounted() {
        window.clearInterval(this.intervalId);
    },
    methods: {
        monthlyReportDatePicked(value: string) {
            this.monthlyReportDate = value;
        },
        createMonthlyReport() {
            if (!this.monthlyReportDate) {
                return;
            }
            const [year, month] = this.monthlyReportDate.split("/");

            this.handleReportAPICall(() =>
                ReportsApi.createMonthlyReport(Number(year), Number(month)),
            );
        },
        yearPickedCHLI(value: number) {
            this.yearCHLI = value;
        },
        quarterPickedCHLI(value: number) {
            this.quarterCHLI = value;
        },
        yearPickedGB(value: number) {
            this.yearGB = value;
        },
        quarterPickedGB(value: number) {
            this.quarterGB = value;
        },
        createQuarterlyReportForCHLI() {
            if (this.yearCHLI && this.quarterCHLI) {
                this.handleReportAPICall(() =>
                    ReportsApi.createQuarterlyReportCHLI(
                        this.yearCHLI,
                        this.quarterCHLI
                    )
                );
            }
        },
        createQuarterlyReportForGB() {
            if (this.yearGB && this.quarterGB) {
                this.handleReportAPICall(() =>
                    ReportsApi.createQuarterlyReportGB(
                        this.yearGB,
                        this.quarterGB
                    )
                );
            }
        },
        costCenterDatePicked(value: string) {
            this.costCenterReportDate = value;
        },
        createCostCenterReport() {
            if (!this.costCenterReportDate) {
                return;
            }
            const [year, month] = this.costCenterReportDate.split("/");
            this.handleReportAPICall(() =>
                ReportsApi.createCostCenterReport(Number(year), Number(month))
            );
        },
        handleReportAPICall(callback: () => Promise<unknown>) {
            // set delay for direct response
            const timeout = setTimeout(() => {
                this.isSpinnerVisible = true;
            }, 500);
            callback()
                .catch(() => {
                    this.isError = true;
                })
                .finally(() => {
                    this.isSpinnerVisible = false;
                    clearTimeout(timeout);
                });
        },
        getImportStatus() {
            ImportsApi.getStatus()
                .then((status: ImportStatus) => {
                    this.isImportRunning = status.importRunning;
                    this.remainingFilesToImport = status.remainingFilesToImport;
                    this.isImportStatusError = false;
                })
                .catch(() => (this.isImportStatusError = true));
        },
    },
});
