
import { defineComponent } from "vue";
import TableInvoices from "@/modules/invoices/TableInvoices.vue";

export default defineComponent({
    name: "PageInvoices",
    components: {
        TableInvoices,
    },
});
