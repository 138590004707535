import { COGNITOGROUP } from "./authentication";

export enum PAGES {
    DASHBOARD = "/",
    INVOICES = "/invoices",
    BANK_STATEMENTS = "/bank-statements",
    REPORTS = "/reports",
    ERROR = "/error",
}

export const routesPermissions = {
    [PAGES.DASHBOARD]: [COGNITOGROUP.ANY],
    [PAGES.ERROR]: [COGNITOGROUP.ANY],
    [PAGES.INVOICES]: [
        COGNITOGROUP.ADMINISTRATOR,
        COGNITOGROUP.FIBU,
        COGNITOGROUP.FIRST_LEVEL_SUPPORT,
        COGNITOGROUP.SECOND_LEVEL_SUPPORT,
        COGNITOGROUP.ZAHLUNGSEINGANGS_ZUORDNUNG,
    ],
    [PAGES.BANK_STATEMENTS]: [
        COGNITOGROUP.KONTO_OPS,
        COGNITOGROUP.FIBU,
        COGNITOGROUP.ADMINISTRATOR,
        COGNITOGROUP.ZAHLUNGSEINGANGS_ZUORDNUNG,
    ],
    [PAGES.REPORTS]: [COGNITOGROUP.FIBU],
};
