<template>
    <div class="content-filters">
        <slot name="filters"></slot>
    </div>
    <div class="content-filters bottom-line">
        <slot name="filters-bottom"></slot>
    </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    name: "ContentFilters",
});
</script>
<style lang="scss">
@import "src/styles/variables";

.content-filters {
    display: flex;
    justify-items: stretch;

    .q-field {
        min-width: 140px;
        flex-grow: 1;
    }

    > * + * {
        padding-left: $spacing-2;
    }

    &.bottom-line {
        justify-content: flex-end;
        margin-bottom: $spacing-4;
    }
}
</style>
