const MonthInputService = {
    isValidDateRange: (
        dateString: string,
        minYearMonthLimit: string,
        maxYearMonthLimit: string
    ): boolean => {
        const regexDate = new RegExp("^\\d{4}\\/\\d{1,2}$");

        const dateElements = dateString.split("/");
        const yearDate = +dateElements[0];
        const monthDate = +dateElements[1];

        const dateMaxLimitElements = maxYearMonthLimit.split("/");
        const yearMaxLimit = +dateMaxLimitElements[0];
        const monthMaxLimit = +dateMaxLimitElements[1];

        const dateMinLimitElements = minYearMonthLimit.split("/");
        const yearMinLimit = +dateMinLimitElements[0];

        const isStringValid = regexDate.test(dateString);
        const isMonthValid = monthDate <= 12 && monthDate > 0;
        const isDateMinRangeValid = yearDate >= yearMinLimit;
        const isDateMaxRangeValid =
            yearDate < yearMaxLimit
                ? true
                : yearDate == yearMaxLimit && monthDate <= monthMaxLimit;

        return (
            isStringValid &&
            isMonthValid &&
            isDateMinRangeValid &&
            isDateMaxRangeValid
        );
    },
    normalizeDate: (
        newValue: string,
        minYearMonthLimit: string,
        maxYearMonthLimit: string
    ): string => {
        if (!newValue) {
            return "";
        } else {
            const dateElements = newValue.split(".");
            const year = dateElements[1];
            const month = dateElements[0];
            const transformedDate = `${year}/${month}`;
            if (
                MonthInputService.isValidDateRange(
                    transformedDate,
                    minYearMonthLimit,
                    maxYearMonthLimit
                )
            ) {
                return transformedDate;
            }

            throw new Error("not a valid date");
        }
    },
    transformDateForUser: (
        newValue: string,
        minYearMonthLimit: string,
        maxYearMonthLimit: string
    ): string => {
        if (!newValue) {
            return "";
        } else {
            const dateElements = newValue.split("/");
            const year = dateElements[0];
            const month =
                dateElements[1].length === 1
                    ? dateElements[1].padStart(2, "0")
                    : dateElements[1];
            if (
                MonthInputService.isValidDateRange(
                    newValue,
                    minYearMonthLimit,
                    maxYearMonthLimit
                )
            ) {
                return `${month}.${year}`;
            }

            throw new Error("not a valid date");
        }
    },
};

export default MonthInputService;
