import "./styles/quasar.scss";
import lang from "quasar/lang/de.js";
import "@quasar/extras/material-icons/material-icons.css";
import Dialog from 'quasar/src/plugins/Dialog.js';;

export default {
    config: {
        brand: {
            primary: "#0e76aa",
            secondary: "#0e76aa",
            accent: "#6e9325",

            dark: "#1a2327",

            positive: "#add65c",
            negative: "#e83030",
            info: "#cfedfc",
            warning: "#ff9f19",
        },
    },
    plugins: {
        Dialog,
    },
    lang: lang,
};
