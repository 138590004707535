import { createApp } from "vue";
import Quasar from 'quasar/src/vue-plugin.js';;

import App from "@/App.vue";
import router from "@/router";
import quasarOptions from "@/quasar-options";
import i18n from "@/i18n";
import "@/styles/main.scss";
import {
    authenticateUser,
    initAmplifyAuthentication,
} from "@/modules/authentication/authenticationService";
import setupAuthInterceptor from "@/modules/authentication/authenticationInterceptor";
import setupErrorInterceptor from "@/modules/error/errorInterceptor";
import { setApiDefaults } from "./config/api";

initAmplifyAuthentication();

authenticateUser().then(() => {
    setApiDefaults();
    setupAuthInterceptor();
    setupErrorInterceptor();

    const app = createApp(App);
    app.use(Quasar, quasarOptions);
    app.use(router);
    app.use(i18n);
    app.mount("#app");
});
