import axios from "axios";

const CountryApi = {
    path: "countries",
    getCountries: async (): Promise<string[]> => {
        return axios
            .get<{ data: string[] }>(CountryApi.path)
            .then(({ data }) => data.data);
    },
};

export default CountryApi;
