import axios from "axios";
import { ImportStatus } from "@/models/ImportStatus";

const ImportsApi = {
    getStatus: async (): Promise<ImportStatus> => {
        return axios
            .get<ImportStatus>("import-files/status")
            .then(({ data }) => data);
    },
};

export default ImportsApi;
