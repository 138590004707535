import { render } from "./DialogUploadBankStatements.vue?vue&type=template&id=5cb8567c&scoped=true"
import script from "./DialogUploadBankStatements.vue?vue&type=script&lang=ts"
export * from "./DialogUploadBankStatements.vue?vue&type=script&lang=ts"

import "./DialogUploadBankStatements.vue?vue&type=style&index=0&id=5cb8567c&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-5cb8567c"

export default script
import QFile from 'quasar/src/components/file/QFile.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QLinearProgress from 'quasar/src/components/linear-progress/QLinearProgress.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QFile,QIcon,QSelect,QLinearProgress});
